import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default function createStore() {
	return new Vuex.Store({
		state: {
			host: '',
			deviceType: 'pc',
			// deviceType: 'mobile',
			showDebug: false,
			adsensConfig: {
				// scriptUrl: 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7151867298880152',
				ads: 'google.com, pub-7151867298880152, DIRECT, f08c47fec0942fa6',
				home_times: [
					{ google_ad_client: 'ca-pub-7151867298880152', google_ad_slot: '1788093577', google_ad_width: 300, google_ad_height: 250 },
					{ google_ad_client: 'ca-pub-7151867298880152', google_ad_slot: '7684353862', google_ad_width: 300, google_ad_height: 250 },
					{ google_ad_client: 'ca-pub-7151867298880152', google_ad_slot: '6371272194', google_ad_width: 300, google_ad_height: 250 },
				],
				home_full: { google_ad_client: 'ca-pub-7151867298880152', google_ad_slot: '2447676349', google_ad_width: 300, google_ad_height: 250 },
				home_blog: { google_ad_client: 'ca-pub-7151867298880152', google_ad_slot: '1066974628', google_ad_width: 300, google_ad_height: 250 },
				blog_1_1: { google_ad_client: 'ca-pub-7151867298880152', google_ad_slot: '4996710845', google_ad_width: 300, google_ad_height: 250 },
				blog_1_2: { google_ad_client: 'ca-pub-7151867298880152', google_ad_slot: '6127729619', google_ad_width: 300, google_ad_height: 250 },
			},
			adPosConfig: {
				home_pc: [3, 9, 18],
				home_m: [1, 3, 6],
			},
			firebaseConfig: {
				apiKey: 'AIzaSyBcS3cwlUXpK99s0FiNLcdhiTqTbqa8pRo',
				authDomain: 'webs-58a8d.firebaseapp.com',
				projectId: 'webs-58a8d',
				storageBucket: 'webs-58a8d.appspot.com',
				messagingSenderId: '730684174767',
				appId: '1:730684174767:web:a7b48afc79c7a6f80c3f5a',
				measurementId: 'G-J6ZDGQ4QS9',
			},
		},
		mutations: {
			setHost(state, host) {
				state.host = host
			},
			setTitle(state, title) {
				state.title = title
			},
			setDomain(state, domainConfig) {
				state.domainConfig = domainConfig
			},
			setDeviceType(state, deviceType) {
				state.deviceType = deviceType
			},
			setDebug(state, showDebug) {
				state.showDebug = showDebug
			},
		},
		getters: {
			showDebug: (state) => state.showDebug,
			deviceType: (state) => state.deviceType,
			host: (state) => state.host,
			adsensConfig: (state) => state.adsensConfig,
			adPosConfig: (state) => state.adPosConfig,
		},
	})
}
